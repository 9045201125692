// External
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Venue from 'models/venue';
import Brand from 'models/brand';

const propTypes = {
  venue: PropTypes.instanceOf(Venue).isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
};

const Header = ({ venue, brand }) => {
  const isBestParking = brand.isBestParking;
  const venueHeaderBackground = cx({
    'venue-header-wrapper': true,
    'background-color-watermelon': !isBestParking,
    'background-color-bp-dark-blue': isBestParking,
  });

  // Note: The Venue model applies imageCdnAsset to the default venue image.
  return (
    <div className={venueHeaderBackground}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-6">
            <div className="venue-title">
              <h1 className="title-bar">
                {`${venue.name} Parking`.toUpperCase()}
              </h1>
              <div className="subtitle-bar weak">
                Your space is waiting.
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 hidden-lte-sm">
            <div className="venue-image-address heavy-shadowed">
              <picture>
                <source media="(max-width: 991px)" srcSet="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
                <img src={venue.displayVenueImage} alt={venue.name} />
              </picture>
              <div className="venue-image-text">
                { venue.completeAddress }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;

export default Header;
