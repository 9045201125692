// External libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrderedMap } from 'immutable';
import { canUseDOM } from 'exenv';

import Events from 'components/venue/events';
import SeeAllParkingNearby from 'components/venue/see-all-parking-nearby';
import About from 'components/venue/about';
import TextToDownload from 'components/common/text-to-download';
import VenuePackages from 'components/venue/packages';
import HowItWorks from 'components/common/how-it-works';
import Header from 'components/venue/header';
import JsonLD from 'components/common/json-ld';
import UPExpressBanner from 'components/venue/up-express-banner';

import VenueModel from 'models/venue';
import { filterByName } from 'models/event';
import Search from 'models/search';
import EventVenue from 'models/schema.org/event-venue';
import Brand from 'models/brand';

import { PAGEVIEW_EVENT } from 'lib/analytics/events';
import { pageProps, urlProps } from 'lib/analytics/page-properties';
import * as AppContext from 'lib/app-context';

import initializeVenue from 'action-creators/search/initialize-venue';
import cleanupVenueState from 'action-creators/search/cleanup-venue-state';
import trackEventCreator from 'action-creators/analytics/track-event';
import setModalState from 'action-creators/modal/set-modal-state';
import sendAppDownloadTextCreator from 'action-creators/brand/send-app-download-text';
import scrollToTop from 'action-creators/scroll-to-top';

const propTypes = {
  venue: PropTypes.instanceOf(VenueModel).isRequired,
  events: PropTypes.instanceOf(OrderedMap).isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  eventName: PropTypes.string,
  currentSearch: PropTypes.instanceOf(Search).isRequired,
  venueInitialized: PropTypes.bool.isRequired,
  trackEvent: PropTypes.func.isRequired,
  changeModalState: PropTypes.func.isRequired,
  sendAppDownloadText: PropTypes.func.isRequired,
  initializeVenue: PropTypes.func.isRequired,
  cleanupVenueState: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  appContext: PropTypes.string,
};

const defaultProps = {
  eventName: null,
  appContext: AppContext.DESKTOP,
};

class Venue extends Component {
  componentWillMount() {
    if (!this.props.venueInitialized) { this.props.initializeVenue(); }
    if (canUseDOM) { this.props.scrollToTop(); }

    if (canUseDOM) {
      const { trackEvent, venue } = this.props;
      const pageProperties = pageProps({ app: 'Venue', venue });
      trackEvent({
        ...PAGEVIEW_EVENT,
        ...pageProperties,
        name: pageProperties.pageName,
        properties: {
          ...urlProps(),
          VenueID: venue.id,
          VenueName: venue.name,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.cleanupVenueState();
  }

  render() {
    const { venue, currentSearch, eventName, brand, appContext } = this.props;
    let { events } = this.props;
    const eventStyles = {
      paddingTop: venue.transientAvailable ? null : '75px',
    };

    if (eventName) {
      events = filterByName(events, eventName);
    }

    return (
      <div className="venue-wrapper">
        <Header venue={venue} brand={brand} />
        <div className="hidden-lte-sm"><SeeAllParkingNearby venue={venue} onClick={this.goToDailyParking} /></div>
        <div style={eventStyles}>
          <Events
            events={events}
            venue={venue}
            currentSearch={currentSearch}
            initialCount={8}
            mobileCount={4}
            changeModalState={this.props.changeModalState}
          />
          <UPExpressBanner venue={venue} trackEvent={this.props.trackEvent} />
        </div>
        <div className="visible-lte-sm"><SeeAllParkingNearby venue={venue} /></div>
        <VenuePackages venue={venue} currentSearch={currentSearch} />
        <div className="bottom-shadow-blur">
          <About venue={venue} changeModalState={this.props.changeModalState} />
        </div>
        <HowItWorks brand={brand} appContext={appContext} />
        <TextToDownload
          className="margin-vertical-40"
          sendAppDownloadText={this.props.sendAppDownloadText}
          brand={this.props.brand}
        />
        <JsonLD schema={new EventVenue(venue)} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    venue,
    events,
    eventName,
    venueInitialized,
    currentSearch,
  } = state.search;

  const { brand } = state.brand;
  const { appContext } = state.app;

  return {
    venue,
    events,
    eventName,
    venueInitialized,
    currentSearch,
    brand,
    appContext,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeModalState: setModalState,
    trackEvent: trackEventCreator,
    sendAppDownloadText: sendAppDownloadTextCreator,
    initializeVenue,
    cleanupVenueState,
    scrollToTop,
  }, dispatch)
);

Venue.propTypes = propTypes;
Venue.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(Venue);
