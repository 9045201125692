import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import VisibilitySensor from 'react-visibility-sensor';

import { imageCdnAsset } from 'lib/common/url-helpers';

import Venue from 'models/venue';

import { UPEXPRESS_IMPRESSION, UPEXPRESS_CLICK } from 'lib/analytics/events';

const propTypes = {
  venue: PropTypes.instanceOf(Venue).isRequired,
  trackEvent: PropTypes.func.isRequired,
};

class UPExpressBanner extends Component {
  constructor(props) {
    super(props);
    this.trackingProps = {
      properties: {
        LocationId: '11915', // https://admin.parkwhiz.com/sellers/2163/locations/11915
        VenueId: props.venue.id,
      },
    };

    this.onClick = this.onClick.bind(this);
  }

  onVisibilityChange(isVisible) {
    if (isVisible) {
      const { trackEvent } = this.props;
      trackEvent({
        ...UPEXPRESS_IMPRESSION,
        ...this.trackingProps,
      });
    }
  }

  onClick() {
    const { trackEvent } = this.props;
    trackEvent({
      ...UPEXPRESS_CLICK,
      ...this.trackingProps,
    });

    window.location = '/p/toronto-parking/5990-airport-rd-4/?venue_id=400163';
  }

  shouldRender() {
    const { venue } = this.props;
    const venueId = get(venue, 'id', '');
    return venue && (venueId === 1048 || venueId === 1044 || venueId === 398381 || venueId === 397917);
  }

  render() {
    if (!this.shouldRender()) { return null; }

    return (
      <VisibilitySensor onChange={v => this.onVisibilityChange(v)} partialVisibility>
        <div className="row background-color-xs-blue-light-grey padding-top-20 padding-bottom-30 text-color-dark-slate">
          <div className="col-sm-3 col-sm-offset-1 col-lg-2 col-lg-offset-1 col-xs-8 col-xs-offset-2">
            <img src={imageCdnAsset('/images/venues/scotiabank-express.png')} alt="Scotiabank Arena Express" />
          </div>
          <div className="col-sm-7 col-sm-offset-0 col-xs-10 col-xs-offset-1">
            <div className="text-weight-bold text-size-sm-20 text-size-xs-18 padding-top-10">Skip City Driving — Take the UP Express</div>
            <div className="text-weight-book text-size-sm-16 text-size-xs-14 padding-top-10">Book parking at Pearson Station then ride the train downtown with the family—all for one great price.</div>
            <div className="padding-top-20">
              <a className="btn btn-primary" onClick={this.onClick}>LEARN MORE</a>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}

UPExpressBanner.propTypes = propTypes;
export default UPExpressBanner;
