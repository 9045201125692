import { Record } from 'immutable';

import PostalAddress from 'models/schema.org/postal-address';
import GeoCoordinates from 'models/schema.org/geo-coordinates';
import env from 'lib/env';
export default class EventVenue extends Record({
  '@context': 'http://schema.org',
  '@type': 'EventVenue',
  'name': null,
  'url': null,
  'image': null,
  'address': new PostalAddress(),
  'geo': new GeoCoordinates(),
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const { NODE_PW_URL } = env();
    const { lat, lng, displayVenueImage, name, url } = props || {};
    super({
      name,
      url: `https:${NODE_PW_URL}${url}`,
      image: `https:${NODE_PW_URL}${displayVenueImage}`,
      address: new PostalAddress(props),
      geo: new GeoCoordinates({ latitude: lat, longitude: lng }),
    });
  }
}
