import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { imageCdnAsset, imageCdnAssetSet } from 'lib/common/url-helpers';

import VenueModel from 'models/venue';

const propTypes = {
  venue: PropTypes.instanceOf(VenueModel).isRequired,
};

const SeeAllParkingNearby = ({ venue }) => {
  if (!venue.transientAvailable && !venue.monthlyAvailable) { return null; }

  return (
    <div className="container see-all-parking-nearby">
      <div className="row">
        <div className="col-xs-12 col-md-6 sm-align-center">
          <div className="row">
            <div className="col-xs-12">
              <img
                className="hidden-lte-sm"
                src={imageCdnAsset('/images/venues/car.png')}
                srcSet={imageCdnAssetSet('/images/venues/car.png 60w, /images/venues/car.png 120w, /images/venues/car.png 180w')}
                alt="car"
              />
              <div className="h2 not-going-to-event">Not going to an event?</div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <img
                className="visible-sm visible-xs"
                src={imageCdnAsset('/images/venues/car.png')}
                srcSet={imageCdnAssetSet('/images/venues/car.png 60w, /images/venues/car.png 120w, /images/venues/car.png 180w')}
                alt="car"
              />
              <Link
                className="see-all-parking-nearby-link"
                to={{
                  pathname: venue.url,
                  search: `?view=list&${venue.transientAvailable ? 'daily' : 'monthly'}=1`,
                  state: {
                    app: { name: 'Search' },
                  },
                }}
              >
                See all parking nearby
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SeeAllParkingNearby.propTypes = propTypes;
export default SeeAllParkingNearby;
