// External libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { imageCdnAsset, imageCdnAssetSet } from 'lib/common/url-helpers';

import VenueModel from 'models/venue';
import Search from 'models/search';

const propTypes = {
  venue: PropTypes.instanceOf(VenueModel).isRequired,
  currentSearch: PropTypes.instanceOf(Search).isRequired,
};

const VenuePackages = ({ currentSearch, venue }) => {
  if (!venue.packagesAvailable) { return null; }

  return (
    <div className="venue-packages">
      <div className="container sm-align-center">
        <span className="packages-header"> Want Series or Season Parking Passes?</span>
        <Link
          className="btn-white-outline"
          to={{
            pathname: `${venue.url}packages/`,
            state: {
              search: {
                currentSearch: currentSearch.set('parkingType', 'Package').toJSON(),
                venue: JSON.stringify(venue.toJSON()),
                displayMap: false,
              },
              app: {
                name: 'Search',
              },
            },
          }}
        >
          FIND PARKING PACKAGES
        </Link>
        <picture>
          <source media="(max-width: 991px)" srcSet="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
          <img
            src={imageCdnAsset('/images/venues/car-from-above.png')}
            srcSet={imageCdnAssetSet('/images/venues/car-from-above.png 203w, /images/venues/car-from-above@2x.png 406w, /images/venues/car-from-above@3x.png 609w')}
            alt="Zag from Above"
          />
        </picture>
      </div>
    </div>
  );
};

VenuePackages.propTypes = propTypes;
export default VenuePackages;
